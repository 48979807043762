<template>
  <div>

    <!-- <v-row>
        <v-col>
          <setting-basic @updateBasicInfos="updateBasicUserInfos($event)" :user="this.$store.getters.getUser">
          </setting-basic>

          <setting-basic>
          </setting-basic>
        </v-col>
      </v-row>
      <v-divider></v-divider> -->

 

    <!-- Personnal infos card -->
    <!-- <v-container> -->
    <v-row>
      <!-- <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="subsecondary" @click="editMyProfile">Edit profile</v-btn>
      </v-col> -->
      <v-col cols="12 mx-0" v-if="this.showactivatedbtn == 0">
        <v-alert color="warning" text class="mb-0">
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>
            <div class="ms-3">
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text small class="subprimary text-capitalize">
                    <span class="text-sm primary--text" v-bind="attrs" v-on="on">Activate your account</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="text-h5 mb-6 py-3 font-weight-black primary secondaryAccent--text">
                    Active your account
                  </v-card-text>

                  <v-card-text class="text-h6">
                    Enter activation you have received
                  </v-card-text>
                  <v-card-text class="my-1">
                    <v-text-field v-model="activatedcode" label="" dense outlined>
                    </v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="dialog = false">
                      Reset
                    </v-btn>
                    <v-btn color="primary" outlined :loading="this.loading ? true : false" @click="activateAccount">
                      Activate
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-4 rounded-lg">
          <div class="pa-4">
            Personnals Informations
          </div>
          <v-divider></v-divider>
          <v-card-text class="d-flex pa-0">
            <div class="col-md-5">User name</div>
            <div class="col-md-7">{{ user.username }}</div>
          </v-card-text>
          <v-card-text class="d-flex pa-0">
            <div class="col-md-5">First name</div>
            <div class="col-md-7">{{ user.firstname }}</div>
          </v-card-text>
          <v-card-text class="d-flex pa-0">
            <div class="col-md-5">Last name</div>
            <div class="col-md-7">{{ user.lastname }}</div>
          </v-card-text>
          <v-card-text class="d-flex pa-0">
            <div class="col-md-5">Adress</div>
            <div class="col-md-7">{{ user.address }}</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="mb-4 rounded-lg">
          <div class="pa-4">
            Contact Informations
          </div>
          <v-divider></v-divider>
          <v-card-text class="d-flex pa-0">
            <div class="col-md-5">Phone number</div>
            <div class="col-md-7">{{ user.phonenumber }}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="d-flex pa-0">
            <div class="col-md-5">Email </div>
            <div class="col-md-7">{{ user.email }}</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="mb-4 rounded-lg">
          <div class="pa-3">
            About me
          </div>
          <v-divider></v-divider>
          <v-card-text class="">
            {{ user.about }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="mb-4 rounded-lg">
          <div class="pa-3">
            My skills:
            <!-- <strong>{{ user.main_skill }}</strong> -->
          </div>
          <v-divider></v-divider>
          <!-- userskill -->
          <v-card-text class="" v-if="userskill">
            <div v-for="(skil, cle) in userskill" :key="cle">{{ skil.fr }}</div>
          </v-card-text>
          <v-card-text v-else class="text-center">You have empty skill for the moment..!</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- </v-container> -->
  </div>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline,
  mdiPhone, mdiMessageText, mdiMapMarker, mdiEmail, mdiWeb,
  mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
  mdiCalendarRange, mdiMapMarkerOutline
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
  components: {
  },
  data() {
    return {
      user: {},
      userskill: [],
      // user: this.$store.getters.getUser,
      showactivatedbtn: this.$store.getters.getUser.isactivated,
      dialog: false,
      loading: false,

      status: ['Active', 'Inactive', 'Pending', 'Closed'],
      activatedcode: '',
    }
  },
  methods: {
    editMyProfile() {
      this.$router.push({ name: 'account.setting' })
    },
    fetchUserInformation() {
      Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
        if (response.success === true) {
          this.user = response.listEntity[0]
        }
      }).catch((err) => { });

      Drequest.api(`lazyloading.user-skill?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
        if (response.success === true) {
          response.listEntity.forEach(element => {
            this.userskill.push(element.skill.name)
          });
        }
        else { }
      }).catch((err) => { });
    },
    activateAccount() {
      var formreset = new FormData();
      formreset.append("activationcode", this.activatedcode);
      this.loading = true;
      Drequest.api(`user.activateaccount?user_id=${this.$store.getters.getUserId}`)
        .data(formreset)
        .post((response) => {
          switch (response.success) {
            case true:
              this.loading = false;
              this.dialog = false
              this.showactivatedbtn = response.user.is_activate
              this.$store.commit("setUser", {
                id: response.user.id,
                isactivated: response.user.is_activated,
              });

              this.$fire({
                type: "success",
                text: response.detail,
              })
              break;
            default:
              this.loading = false; this.$fire({
                type: "error",
                text: "Bad activation code.! \n please check your email adress.!",
              })
              break;
          }
        })
        .catch((err) => {
          window.console.log(err)
          this.loading = false;
        });
    },
  },
  beforeMount() {
    this.fetchUserInformation()
  },
  setup(props) {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiPhone, mdiMessageText, mdiMapMarker, mdiEmail, mdiWeb,
        mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
        mdiCalendarRange, mdiMapMarkerOutline
      },
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -3rem;
  left: 3rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
